import BaseChart from "../charts/BaseChart";
import ChartWrapper from "../charts/ChartWrapper";
import I18n from "../../models/i18n";
import React, { createRef } from "react";
import PropTypes from "prop-types";
import {
  ChartLabel,
  DiscreteColorLegend,
  Hint,
  HorizontalGridLines,
  LineSeries,
  MarkSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis
} from "react-vis";

import GenericInfoModal from "../CommonComponents/GenericChartInfoModal";

class Chart extends BaseChart {
  static propTypes = {
    stations: PropTypes.array,
    selectedStations: PropTypes.array,
    data: PropTypes.array,
    graph: PropTypes.string,
    graphs: PropTypes.array
  };

  state = {
    value: false
  };

  hoveredPoint = null;
  hintValue = "";

  render() {
    let { width, graph, graphs, data, selectedStations } = this.props;

    const { value } = this.state;
    const { h, w } = this.getHeight(width || 600);

    // Linee guida per il grafico NO2:
    const firstRecord = this.getFirstRecord(data);
    const latestRecord = this.getLatestRecord(data);

    // 40 μg/m3 (Normativa Italiana/Europea limite annuale)
    const no2RedLine = [
      { x: firstRecord, y: 40 },
      { x: latestRecord, y: 40 }
    ];
    // 10 μg/m3 (OMS, limite annuale)
    const no2OrangeLine = [
      { x: firstRecord, y: 10 },
      { x: latestRecord, y: 10 }
    ];

    let legendItems = [];

    let yMaxValue = 50,
      yMinValue = 0,
      xStart = 0,
      xEnd = 50,
      cutOffPmVeryGood,
      cutOffPmGood,
      cutOffPmFair,
      cutOffPmPoor,
      cutOffPmVeryPoor,
      title = "",
      subtitle = "",
      label = "",
      yAxisGraph = [null, null];

    // Grafici multipli
    if (graphs) {
      graphs.forEach(graph => {
        let {
          yMaxValue: graphYMaxValue,
          yMinValue: graphYMinValue,

          xStart: graphXStart,
          xEnd: graphXEnd,

          cutOffPmVeryGood: graphCutOffPmVeryGood,
          cutOffPmGood: graphCutOffPmGood,
          cutOffPmFair: graphCutOffPmFair,
          cutOffPmPoor: graphCutOffPmPoor,
          cutOffPmVeryPoor: graphCutOffPmVeryPoor,

          title: graphTitle,
          subtitle: graphSubtitle,
          label: graphLabel,
          yAxisGraph: graphYAxisGraph
        } = this.getChartOptions(graph, data);

        // Imposta i cutoff per i Pm25:
        cutOffPmVeryGood = graphCutOffPmVeryGood;
        cutOffPmGood = graphCutOffPmGood;
        cutOffPmFair = graphCutOffPmFair;
        cutOffPmPoor = graphCutOffPmPoor;
        cutOffPmVeryPoor = graphCutOffPmVeryPoor;

        // X
        if (xStart < graphXStart) xStart = graphXStart;
        if (xEnd < graphXEnd) xEnd = graphXEnd;
        // Y
        if (yMaxValue < graphYMaxValue) yMaxValue = graphYMaxValue;
        if (yMinValue > graphYMinValue) yMinValue = graphYMinValue;

        // Title & Label
        title = title.concat(" ~ ", graphTitle);
        // subtitle = subtitle + graphSubtitle;
        label = label.concat(" ~ ", graphLabel);

        // Sezione delle ordinate (asse Y)
        let yMinAxis = graphYAxisGraph[0];
        let yMaxAxis = graphYAxisGraph[1];

        // Imposta il valore minimo delle ordinate
        if (yAxisGraph[0] === null) {
          yAxisGraph[0] = yMinAxis;
        } else if (yAxisGraph[0] > yMinAxis) {
          yAxisGraph[0] = yMinAxis;
        }

        // Imposta il valore massimo delle ordinate
        if (yAxisGraph[1] === null) {
          yAxisGraph[1] = yMaxAxis;
        } else if (yAxisGraph[1] < yMaxAxis) {
          yAxisGraph[1] = yMaxAxis;
        }

        // Crea la legenda
        selectedStations.forEach(station => {
          legendItems.push(this.addLegendItem(graph, station));
        });
      });

      title = title.replace("~", "");
      label = label.replace("~", "");
    }

    // Grafico singolo
    else if (graph) {
      ({
        yMaxValue,
        yMinValue,
        xStart,
        xEnd,
        cutOffPmVeryGood,
        cutOffPmGood,
        cutOffPmFair,
        cutOffPmPoor,
        cutOffPmVeryPoor,

        title,
        subtitle,
        label,
        yAxisGraph
      } = this.getChartOptions(graph, data));
    }

    return (
      <div>
        <ChartWrapper
          title={title}
          subtitle={subtitle}
          modalContent={<GenericInfoModal />}
        >
          <XYPlot
            xType="time"
            width={w}
            height={h}
            yDomain={yAxisGraph}
            onMouseLeave={() => this.setState({ value: false })}
            onTouchEnd={() => this.setState({ value: false })}
            margin={
              width < 500
                ? { left: 50, right: 10, top: 10, bottom: 50 }
                : { left: 60, right: 10, top: 10, bottom: 50 }
            }
          >
            <HorizontalGridLines />
            <VerticalGridLines />
            <XAxis title="Time" tickLabelAngle={-45} />
            <YAxis />
            <ChartLabel
              text={label}
              className="alt-y-label"
              includeMargin={false}
              xPercent={width >= 900 ? -0.04 : width >= 500 ? -0.075 : -0.12}
              yPercent={0.5}
              style={{
                transform: "rotate(-90)",
                textAnchor: "middle"
              }}
            />
            {this.props.selectedStations.map((station, i) => {
              if (!station) {
                return null;
              }
              const { id, color } = this.getStation(station);

              let data = [];
              let dataSets = [];

              const graphs = this.props.graphs;

              if (graphs) {
                dataSets = graphs.map(graph => {
                  return this.props.data
                    .filter(item => item.stationId === id)
                    .map(item => {
                      return {
                        id: graph,
                        station: station,
                        x: new Date(item.recordedAt),
                        y: this.getYAxisForGraph(item, graph)
                      };
                    });
                });
              } else {
                const param = this.props.graph;

                data = this.props.data
                  .filter(item => item.stationId === id)
                  .map(item => {
                    return {
                      id: param,
                      station: station,
                      x: new Date(item.recordedAt),
                      y: this.getYAxisForGraph(item, param)
                    };
                  });
              }

              return dataSets.map((data, index) => {
                return (
                  <LineSeries
                    key={"custom-id-" + index}
                    data={data}
                    curve={"curveMonotoneX"}
                    onNearestX={value => {
                      this.setState({
                        value,
                        ts: value.x,
                        [value.station + "_" + value.id]: {
                          value: value.y,
                          graph: value.id
                        }
                      });
                    }}
                    color={color}
                    title={color}
                  />
                );
              });
            })}
            {/* PM25 */}
            {(graph === "pm25" || graphs.includes("pm25")) && [
              <LineSeries
                key={0}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmVeryGood}
                stroke="#1DE3E0"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Very Good"
              />,
              <LineSeries
                key={1}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmGood}
                stroke="#1DE326"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Good"
              />,
              <LineSeries
                key={2}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmPoor}
                stroke="#FFEC33"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Fair"
              />,
              <LineSeries
                key={3}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmFair}
                stroke="#FF9C33"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Poor"
              />,
              <LineSeries
                key={4}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={cutOffPmVeryPoor}
                stroke="#FF0000"
                strokeStyle="dashed"
                strokeWidth={3}
                title="Very poor"
              />
            ]}
            {/* NO2 */}
            {(graph === "nox" || graphs.includes("nox")) && [
              <LineSeries
                key={5}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={no2RedLine}
                stroke="#FF0000"
                strokeStyle="dashed"
                strokeWidth={3}
                title="no2_red"
              />,
              <LineSeries
                key={6}
                style={{ zIndex: "800", opacity: "0.3" }}
                data={no2OrangeLine}
                stroke="#FF9C33"
                strokeStyle="dashed"
                strokeWidth={3}
                title="no2_orange"
              />
            ]}

            <DiscreteColorLegend
              items={legendItems} // Elenco di elementi con titolo e colore
              orientation="horizontal"
            />

            {value && (
              <Hint value={value}>
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    paddingInline: "10px",
                    borderRadius: "10px",
                    boxShadow: "",
                    color: "black",
                    fontSize: "14px",
                    paddingTop: "3px",
                    paddingBottom: "3px"
                  }}
                >
                  Data:{" "}
                  <strong>{new Date(this.state.ts).toLocaleString()}</strong>
                  {this.props.selectedStations.map(station => {
                    return this.props.graphs.map(graph => {
                      return (
                        <div key={station + "-" + graph}>
                          {this.getStation(station).name}
                          {this.getHintContent(station, graph)
                            ? this.getGraphLabel(
                                this.getHintContent(station, graph).graph
                              )
                            : ""}{" "}
                          :{" "}
                          <strong>
                            {" "}
                            {this.getHintContent(station, graph)
                              ? this.getHintContent(station, graph).value
                              : "no data"}
                          </strong>
                        </div>
                      );
                    });
                  })}
                </div>
              </Hint>
            )}
          </XYPlot>
        </ChartWrapper>
      </div>
    );
  }

  getStation = code => {
    for (let i = 0; i < this.props.stations.length; i++) {
      if (this.props.stations[i].code === code) {
        return this.props.stations[i];
      }
    }
    console.error('Unable to find station: "%s"', code);
    return false;
  };

  /**
   *
   * @param {*} item
   * @param {*} graph
   * @returns
   */
  getYAxisForGraph(item, graph) {
    if (graph === "pm25") return item.PM2_5;

    return item[graph];
  }
  /**
   *
   * @param {*} graph
   * @returns
   */
  getGraphTitle(graph) {
    if (graph === "temp") {
      return I18n.translate("data", "tabellaTemp_title");
    } else if (graph === "pm25") {
      return I18n.translate("data", "tabellaPM25_title");
    } else if (graph === "o3") {
      return I18n.translate("data", "tabella_o3_title");
    } else if (graph === "hum") {
      return I18n.translate("data", "tabellaHum_title");
    } else if (graph === "nox") {
      return I18n.translate("data", "tabella_nox_title");
    }
  }
  /**
   *
   * @param {*} graph
   * @returns
   */
  getGraphSubtitle(graph) {
    if (graph === "temp") {
      return I18n.translate("data", "tabellaTemp_subtitle");
    } else if (graph === "pm25") {
      return I18n.translate("data", "tabellaPM25_subtitle");
    } else if (graph === "o3") {
      // subtitle = I18n.translate('data', 'tabellao3_subtitle');
    } else if (graph === "hum") {
      return I18n.translate("data", "tabellaHum_subtitle");
    } else if (graph === "nox") {
      // subtitle = I18n.translate('data', 'tabellaHum_subtitle');
    }
  }
  /**
   *
   * @param {*} graph
   * @returns
   */
  getGraphLabel(graph) {
    if (graph === "temp") {
      return "° C";
    } else if (graph === "pm25") {
      return "PM 2.5";
    } else if (graph === "o3") {
      return "O3";
    } else if (graph === "hum") {
      return "Umidità";
    } else if (graph === "nox") {
      return "NOx";
    }
  }
  /**
   *
   * @param {*} graph
   * @returns
   */
  getGraphYAxis(graph) {
    if (graph === "temp") {
      return [-15, 45];
    }
  }
  /**
   *
   * @param {*} graph
   * @param {*} station
   */
  addLegendItem(graph, station) {
    const { id, color, name } = this.getStation(station);

    return { color, title: name + " - " + this.getGraphTitle(graph) };
  }
  /**
   *
   * @param {*} graph
   * @returns
   */
  getChartOptions(graph, data) {
    const yValuesArray = data.map(item => {
      if (graph === "pm25") {
        return item.PM2_5;
      } else {
        return item[graph];
      }
    });

    const yMaxValue = Math.max.apply(Math, yValuesArray);
    const yMinValue = Math.min.apply(Math, yValuesArray);

    const xStart = new Date(data[0].recordedAt);
    const xEnd = new Date(data[data.length - 1].recordedAt);

    // Linea Pm2.5 azzurra
    const cutOffPmVeryGood = [
      { x: xStart, y: 0.2 },
      { x: xEnd, y: 0.2 }
    ];
    // Linea Pm2.5 verde
    const cutOffPmGood = [
      { x: xStart, y: 10 },
      { x: xEnd, y: 10 }
    ];
    // Linea Pm2.5 arancione
    const cutOffPmFair = [
      { x: xStart, y: 15 },
      { x: xEnd, y: 15 }
    ];
    // Linea Pm2.5 gialla
    const cutOffPmPoor = [
      { x: xStart, y: 25 },
      { x: xEnd, y: 25 }
    ];
    // Linea Pm2.5 rossa
    const cutOffPmVeryPoor = [
      { x: xStart, y: 40 },
      { x: xEnd, y: 40 }
    ];

    let title = this.getGraphTitle(graph) ?? "";
    let subtitle = this.getGraphSubtitle(graph) ?? "";
    let label = this.getGraphLabel(graph) ?? "";
    let yAxisGraph = [yMinValue, yMaxValue];

    return {
      yMaxValue,
      yMinValue,
      xStart,
      xEnd,
      cutOffPmVeryGood,
      cutOffPmGood,
      cutOffPmFair,
      cutOffPmPoor,
      cutOffPmVeryPoor,

      title,
      subtitle,
      label,
      yAxisGraph
    };
  }
  /**
   *
   * @param {*} station
   * @param {*} graph
   * @returns
   */
  getHintContent(station, graph) {
    const composedId = `${station}_${graph}`;

    if (this.state) {
      return this.state[composedId];
    }
  }
  /**
   *
   * @param {*} data
   * @returns
   */
  getFirstRecord(data) {
    return new Date(data[0].recordedAt);
  }

  /**
   *
   * @param {*} data
   * @returns
   */
  getLatestRecord(data) {
    return new Date(data[data.length - 1].recordedAt);
  }
}

export default Chart;
