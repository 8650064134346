import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import I18n from "../../models/i18n";
import qs from "qs";
import randomColor from "randomcolor";
import Timeframe from "../CommonComponents/Timeframe";
import DatePicker from "../CommonComponents/DatePicker";
import { connect } from "react-redux";
import Chart from "./Chart";
import Loading from "../CommonComponents/Loading";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
  Checkbox,
  FormControlLabel
} from "@material-ui/core";
import moment from "moment";

const graphs = [
  { id: "pm25", desc: "PM 2.5" },
  { id: "temp", desc: "Temperature" },
  { id: "nox", desc: "NOx" },
  { id: "o3", desc: "03" },
  { id: "hum", desc: "Umidità" }
];

const colors = [
  ...randomColor({ count: 10, hue: "random", luminosity: "dark" })
];

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

/*
  [
  '#339933',
  '#375e97',
  '#fb6542',
  '#ffbb00',
  '#7a5555',
  '#7f36e5',
  '#dabfff',
  '#37b680',
  '#b580ff',
  '#2d006b',
  '#644c72'
];
*/

class StatsPage extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  state = {
    stations: false,
    data: null,
    width: undefined,
    resizing: false
  };

  refCol = React.createRef();

  render() {
    const { stations, data, width, resizing } = this.state;
    if (!stations) {
      return <Loading />;
    }
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const selectedStations = this.getStations(queries);
    const isStationSelected = function(code) {
      return selectedStations.indexOf(code) >= 0;
    };
    let showCharts = false;
    if (data) {
      showCharts = !!(data.length > 0 && width && !resizing && queries.g);
    }
    const timeframe = this.getTimeframe(queries);

    return (
      <div className="mt-4 stats-page">
        <div className="top-page bg-grey">
          <div className="container-fluid">
            {/* Stazioni */}
            <p>{I18n.translate("stats", "select_stations")}</p>
            <div className="mt-4 row ">
              {stations.rows.map((station, id) => {
                return (
                  <div
                    key={id}
                    className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={"stationId" + id}
                          checked={isStationSelected(station.code)}
                          onChange={e => this.onChangeStation(e, station)}
                          name={"stationId" + id}
                        />
                      }
                      label={station.name}
                      style={{ color: station.color || "red" }}
                    />
                    {/* <label
                        className="form-check-label"
                        htmlFor={"stationId" + id}
                        style={{ color: station.color || "red" }}
                      >
                        {station.name}
                      </label> */}
                  </div>
                );
              })}
            </div>
            <hr />
            <p>{I18n.translate("stats", "filters")}</p>

            {/* Seleziona grafico */}
            <div className="row pb-4 gy-5">
              <div className="col-12 col-md-6 col-lg-2">
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="graphs-label">
                    {I18n.translate("stats", "select_graph")}
                  </InputLabel>
                  <Select
                    labelId="graphs-label"
                    label={I18n.translate("stats", "select_graph")}
                    value={queries.g}
                    onChange={this.onChangeGraph}
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={null}>Nessuno</MenuItem>
                    {graphs.map((graph, i) => {
                      return (
                        <MenuItem key={i} value={graph.id}>
                          {" "}
                          {graph.desc}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* Compara grafico */}
              <div className="col-12 col-md-6 col-lg-3">
                <FormControl variant="outlined" size="small" fullWidth>
                  <InputLabel id="compare-graps-label">
                    {I18n.translate("stats", "compare_graph")}
                  </InputLabel>
                  <Select
                    labelId="compare-graps-label"
                    label={I18n.translate("stats", "compare_graph")}
                    multiple
                    value={this.getCompareGraphs()}
                    onChange={this.onChangeCompareGraph}
                    // renderValue={selected => (
                    //   <div>
                    //     {selected.map(value => (
                    //       <Chip key={value} label={value} />
                    //     ))}
                    //   </div>
                    // )}
                    disabled={!queries.g}
                    MenuProps={MenuProps}
                  >
                    {this.filterCompareGraphsOptions().map((graph, i) => {
                      return (
                        <MenuItem key={graph.id} value={graph.id}>
                          {graph.desc}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
              {/* Timeframe */}
              <div className="col-12 col-md-6 col-lg-3">
                <Timeframe
                  timeframe={timeframe}
                  pushTimeframe={this.pushTimeframe}
                />
              </div>
              <div className="col-12 col-md-6 col-lg-4">
                <div>
                  {timeframe === "custom" && (
                    <DatePicker
                      // range={this.getRange()}
                      startDate={this.getStartDateFilter()}
                      endDate={this.getEndDateFilter()}
                      lang={this.props.lang}
                      onDateRangeChanged={this.onDateRangeChanged}
                      showCharts={showCharts}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {data && (
          <div className="my-5">
            <div className="container">
              {data.length === 0 && (
                <div>{I18n.translate("error", "no_data")}</div>
              )}
              <div className="row">
                <div className="text-center col-12" ref={this.refCol}>
                  {showCharts && (
                    <Chart
                      stations={stations.rows}
                      selectedStations={selectedStations}
                      data={data}
                      graph={queries.g}
                      graphs={this.getAllGraphs()}
                      width={width}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.fetchStations();
    window.addEventListener("resize", this.checkDimensions);
    this.checkDimensions(undefined, true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.loadData();
    }
    if (this.state.width === undefined) {
      this.checkDimensions(undefined, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkDimensions);
  }

  checkDimensions = (e, force = false) => {
    if (this.refCol && this.refCol.current) {
      if (this.state.width !== this.refCol.current.offsetWidth) {
        if (force) {
          this.setState({ width: this.refCol.current.offsetWidth });
          return;
        }
        if (!this.state.resizing) {
          this.setState({ resizing: true });
        }
        if (this.timeoutResize) {
          clearTimeout(this.timeoutResize);
        }
        this.timeoutResize = setTimeout(() => {
          this.setState({
            width: this.refCol.current.offsetWidth,
            resizing: false
          });
        }, 300);
      }
    }
  };

  loadData = () => {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const stations = this.getStations(queries);
    if (stations.length < 1) {
      this.setState({ data: null });
      return;
    }
    const stationsId = [];
    const allStations = Object.assign([], this.state.stations.rows);
    stations.forEach(station => {
      for (let i = 0; i < allStations.length; i++) {
        if (allStations[i].code === station) {
          stationsId.push(allStations[i].id);
          allStations.splice(i, 1);
          break;
        }
      }
    });

    let graphs = null;

    if (queries.g && queries.c) {
      graphs = [queries.g, queries.c];
    }

    const graph = queries.g;
    if (!graph) {
      this.setState({ data: null });
      return;
    }
    const timeframe = this.getTimeframe(queries);
    if (!timeframe) {
      this.setState({ data: null });
      return;
    }
    let range;
    if (timeframe === "custom") {
      range = this.getRange(queries);
      if (!range) {
        this.setState({ data: null });
        return;
      }
    }
    const q = {
      stations: stationsId,
      graph,
      graphs,
      timeframe,
      range
    };

    fetch(
      "/api/v1/stats" +
        qs.stringify(q, { addQueryPrefix: true, arrayFormat: "comma" })
    )
      .then(res => {
        return res.json();
      })
      .then(data => this.setState({ data }));
  };

  fetchStations() {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const { type } = queries;
    const url = `/api/v1/stations${type ? "?station_type=" + type : ""}`;
    fetch(url)
      .then(res => {
        return res.json();
      })
      .then(stations => {
        stations.rows.forEach((station, i) => {
          station.color = colors[i % colors.length];
        });
        this.setState({ stations });
        this.loadData();
      });
  }

  onChangeStation = (e, station) => {
    // get stations array from query.
    const stations = this.getStations();
    if (e.target.checked) {
      // add to array
      if (stations.indexOf(station.code) < 0) {
        stations.push(station.code);
      }
    } else {
      // remove from array
      const p = stations.indexOf(station.code);
      if (p >= 0) {
        stations.splice(p, 1);
      }
    }
    this.setStations(stations);
  };

  changeUrl = params => {
    this.props.history.push(
      this.props.location.pathname +
        qs.stringify(params, { addQueryPrefix: true })
    );
  };

  onChangeGraph = e => {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    queries.g = e.target.value;

    if (!queries.g) {
      queries.c = "";
    }

    this.changeUrl(queries);
  };

  onChangeCompareGraph = e => {
    const selectedOptions = e.target.value ?? [];

    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    queries.c = selectedOptions.join(",");

    if (!queries.c) {
      delete queries.c;
    }

    this.changeUrl(queries);
  };

  getStations = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
    }
    const stations = queries.s || "";
    if (!stations) {
      return [];
    }
    return stations.split(",");
  };

  setStations = stations => {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    queries.s = stations.join(",");
    this.changeUrl(queries);
  };

  getTimeframe = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
    }
    return queries.p || "last_day";
  };

  getRange = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
    }
    return queries.r;
  };

  getStartDateFilter = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
    }

    if (!queries.r) return null;

    const date = queries.r.split("|")[0];
    return moment(date, "YYYYMMDD");
  };

  getEndDateFilter = queries => {
    if (!queries) {
      queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
    }

    if (!queries.r) return null;

    const date = queries.r.split("|")[1];
    return moment(date, "YYYYMMDD");
  };

  pushTimeframe = e => {
    // e.target.blur();
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const timeframe = e.target.value;
    if (timeframe !== "custom") {
      queries.r = "";
    }
    queries.p = timeframe;
    this.changeUrl(queries);
  };

  onDateRangeChanged = range => {
    if (range) {
      const queries = qs.parse(this.props.location.search, {
        ignoreQueryPrefix: true
      });
      queries.r = range;
      this.changeUrl(queries);
    }
  };

  getCompareGraphs() {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    let compareGraphs = queries.c;
    if (!compareGraphs) return [];

    return compareGraphs.includes(",")
      ? compareGraphs.split(",")
      : [compareGraphs];
  }

  convertGraphsStringToArray(graphs) {
    if (!graphs) return [];
    return graphs.includes(",") ? graphs.split(",") : [graphs];
  }
  /**
   * Sum Graph with Compare Graphs
   */
  getAllGraphs = () => {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });

    const graph = queries.g;
    let compareGraphs = queries.c ?? null;
    
    // Nessun grafico di comparazione inserito
    if (!compareGraphs) {
      return [graph];
    }

    compareGraphs.includes(",")
      ? (compareGraphs = compareGraphs.split(","))
      : (compareGraphs = [compareGraphs]);

    return [graph].concat(compareGraphs ?? []);
  };
  /**
   * Rimuove il grafico selezionato a SX
   * @returns
   */
  filterCompareGraphsOptions() {
    const queries = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    });
    const currentGraph = queries.g;

    return graphs.filter(graph => graph.id != currentGraph);
  }
}

function mapStateToProps(state) {
  const { lang } = state;

  return {
    lang
  };
}

export default connect(mapStateToProps)(StatsPage);
