/**
 *
 * @param {*} data
 * @returns
 */
export function getHighestChartY(data, offset) {
  if (!data) return 100;
  return Math.max(...data.flat().map(obj => obj.y));
}
/**
 *
 * @param {*} data
 * @returns
 */
export function getLowestChartY(data, offset) {
  if (!data) return 0;
  return Math.min(...data.flat().map(obj => obj.y));
}
