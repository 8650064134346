import React from 'react';
import {
  Hint,
  HorizontalGridLines,
  LineSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
  DiscreteColorLegend
} from 'react-vis';
import PropTypes from 'prop-types';
import ChartWrapper from './ChartWrapper';
import BaseChart from './BaseChart';
import I18n from '../../models/i18n';
import { getHighestChartY, getLowestChartY } from '../CommonComponents/utils';

class SerieChart extends BaseChart {
  static propTypes = {
    dataSerie: PropTypes.array.isRequired,
    getDateTime: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired,
    labelY: PropTypes.string,
    ticksX: PropTypes.number,
    sensors: PropTypes.object,
    title: PropTypes.string,
    code: PropTypes.string
  };

  state = {
    valueSerie: false
  };

  render() {
    const { width, dataSerie, sensors, title, code } = this.props;
    const { h, w } = this.getHeight(width);

    let subtitle = '';
    if (sensors.tolerance[code]) {
      subtitle = `( ${title}: ${sensors.tolerance[code]} )`;
    }

    return (
      <ChartWrapper title={I18n.translate('data', `tabella_${code}_title`)} subtitle={subtitle}>
        <div className="legend-wrapper">
          <DiscreteColorLegend
            items={[
              {
                title,
                color: '#244F26'
              }
            ]}
          />
        </div>
        <XYPlot
          xType="time"
          width={w}
          height={h}
          yDomain={[
            getLowestChartY(dataSerie),
            getHighestChartY(dataSerie)
          ]}
          onMouseLeave={() => this.setState({ valueSerie: false })}
          onTouchEnd={() => this.setState({ valueSerie: false })}
          margin={
            width < 500 ? { left: 50, right: 10, top: 10, bottom: 50 } : { left: 60, right: 10, top: 10, bottom: 50 }
          }
        >
          <HorizontalGridLines />
          <VerticalGridLines />
          <XAxis title="Time" tickLabelAngle={-45} tickTotal={this.props.ticksX} />
          <YAxis />

          {dataSerie &&
            dataSerie.length > 0 &&
            dataSerie.map((serie, i) => {
              return (
                <LineSeries
                  key={i}
                  data={serie}
                  onNearestX={(valueSerie, event) => {
                    this.queueNearestX('valueSerie', valueSerie, Math.abs(event.event.offsetX - event.innerX - 10));
                  }}
                  color="#244F26"
                  curve={'curveMonotoneX'}
                />
              );
            })}
          {this.state.valueSerie && (
            <Hint value={this.state.valueSerie}>
              <div style={{ background: 'black', opacity: '0.5', padding: '10px' }}>
                {I18n.translate('data', 'date_time')}:{' '}
                <strong>{this.props.getDateTime(this.state.valueSerie.x)}</strong>
                <br />
                {title}: <strong>{this.state.valueSerie ? this.state.valueSerie.y : '-'}</strong>
              </div>
            </Hint>
          )}
        </XYPlot>
      </ChartWrapper>
    );
  }
}

export default SerieChart;
